import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ModularBlocks from '../components/ModularBlocks';

const NewsSingleTemplate = ({
  data: {
    datoCmsNewsSingle: { seoMetaTags, title, bannerImage, modularBlocks, meta },
  },
}) => {
  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner
          title={title}
          date={{
            text: meta.firstPublishedAtText,
            attribute: meta.firstPublishedAtAttribute,
          }}
          image={bannerImage}
        />
        <ModularBlocks items={modularBlocks} />
      </main>
    </Layout>
  );
};

export const NewsSingleTemplateQuery = graphql`
  query NewsSingleTemplateQuery($id: String!) {
    datoCmsNewsSingle(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      bannerImage {
        fluid(
          maxHeight: 380
          maxWidth: 1600
          imgixParams: { auto: "compress", fit: "crop", h: "380", w: "1600" }
        ) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      modularBlocks {
        ... on DatoCmsContentModularBlock {
          id
          model {
            apiKey
          }
          content
        }
      }
      meta {
        firstPublishedAtText: firstPublishedAt(formatString: "DD MMMM YYYY")
        firstPublishedAtAttribute: firstPublishedAt(formatString: "YYYY-MM-DD")
      }
    }
  }
`;

export default NewsSingleTemplate;
